.nav-vertically-center-items {
    align-items: center;
}
.nav-header {
    background-color: #0A3069;
    margin-bottom: 1rem;
    color: white;
}
.banner {
    color: white;
    font-family: Verdana, Tahoma, Lucida Sans, Arial, Helvetica, san-serif, serif;
    border: none;
    background-color: #0A3069;
}
.banner::before
{
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    border: none;
    background-color: #F8BD28;
    height: 17px;
    background-image: linear-gradient(to left , #F8BD28 35%, #FDEC7F 90%);
    background-image: -o-linear-gradient(left , #F8BD28 35%, #FDEC7F 90%);
    background-image: -moz-linear-gradient(left , #F8BD28 35%, #FDEC7F 90%);
    background-image: -webkit-linear-gradient(left , #F8BD28 35%, #FDEC7F 90%);
    background-image: -ms-linear-gradient(left , #F8BD28 35%, #FDEC7F 90%);
    background-image: -webkit-gradient(
            linear,
            left top,
            right top,
            color-stop(0.35, #F8BD28),
            color-stop(0.9, #FDEC7F)
    );
}

.banner-flag {
    position: absolute;
    top: 17px;
    right: 0;
    display: flex;
    flex-shrink: 0;
}

.banner-text {
    margin-top: 17px;
}

.nav-link {
    padding: 0;
}